import React, { useState, useEffect, useMemo } from "react";
import { multicall } from '@wagmi/core';
import ReactGA from "react-ga";
import { BigNumber, ethers } from "ethers";
import { useBalance, useAccount, usePrepareContractWrite, useContractWrite } from "wagmi";
import { Input, Button, List, ListItem, Box, FormControl, FormLabel, FormHelperText, Divider } from "@mui/joy";

import {address as lgeAddress, abi, weth, erc20abi} from "./contract";

function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

export const Lge = () => {
  const { address } = useAccount();
  const { data: userBalance} = useBalance({address, watch: true, cacheTime: 2_000})

  const lgeTotalPegg = ethers.utils.parseEther("5000000")
  const [lgeStart, setLgeStart] = useState(0);
  const [lgeEnd, setLgeEnd] = useState(0);
  const [lgeStatus, setLgeStatus] = useState("");
  const [lgeAmount, setLgeAmount] = useState<number>();
  const [lgeBalance, setLgeBalance] = useState<number|undefined>(undefined);
  const [lgeBonus, setLgeBonus] = useState(0);
  const [lgeTotalEth, setLgeTotalEth] = useState<BigNumber>(BigNumber.from("0"));

  const referrer = useMemo(() => {
    const currentReferrer = localStorage.getItem("referrer")
    if (currentReferrer) return currentReferrer;

    const newReferrer = getQueryVariable("referrer");
    if (newReferrer) {
      localStorage.setItem("referrer", newReferrer)
      return newReferrer;
    }
  }, []);

  const { config } = usePrepareContractWrite({
    address: lgeAddress,
    abi: abi,
    functionName: "buyETH",
    args: [referrer ?? ethers.constants.AddressZero],
    overrides: {value: ethers.utils.parseEther(`${lgeAmount ?? 0}`)}
  })
  const { write: buy } = useContractWrite(config)

  useEffect(() => {
    if (!!userBalance) {
      const round = ethers.utils.parseEther("0.01")
      setLgeAmount(+ethers.utils.formatEther(userBalance?.value.div(2).div(round).mul(round)))
    }
  }, [address])

  useEffect(() => {
    const fetchData = async () => {
      const data = await multicall({
        contracts: [
          {address: lgeAddress, abi, functionName: "lgeStart"},
          {address: lgeAddress, abi, functionName: "lgeEnd"},
          {address: weth, abi: erc20abi, functionName: "balanceOf", args: [lgeAddress]},
          {address: lgeAddress, abi, functionName: "balanceOf", args: [address ?? ethers.constants.AddressZero]}
        ]
      })
      setLgeStart((data[0] as BigNumber).toNumber())

      try {
        setLgeEnd((data[1] as BigNumber).toNumber())
      } catch {
        // lge not started and in lgeEnd uint256.max;
        setLgeEnd(0);
      }

      setLgeTotalEth(data[2] as BigNumber)
      if (lgeTotalEth && lgeTotalEth.lt(ethers.utils.parseEther("50"))) setLgeBonus(10);
      else if (lgeTotalEth && lgeTotalEth.lt(ethers.utils.parseEther("100"))) setLgeBonus(5);

      setLgeBalance((data[3] as BigNumber).toNumber());
    }
    fetchData()
  }, [address, userBalance])

    const formatTimedelta = (td: number) => {
        let hours = Math.floor(td / 3600);
        let minutes = Math.floor((td - (hours * 3600)) / 60);
        let seconds = Math.floor(td - (hours * 3600) - (minutes * 60));

        return `${hours > 9 ? hours : "0"+hours }:${minutes > 9 ? minutes : "0"+minutes}:${seconds > 9 ? seconds : "0"+seconds}`
    }

    const calculateLgeStatus = () => {
        const now = +new Date() / 1000;

        if (now < lgeStart) {
            setLgeStatus(`Starts in ${formatTimedelta(lgeStart - now)}`)
        } else if (now < lgeEnd) {
            setLgeStatus(`Ends in ${formatTimedelta(lgeEnd - now)}`)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => calculateLgeStatus(), 1000);
        return () => clearInterval(interval);
      }, [lgeStart, lgeEnd]);

    return <div className="lge">
        <h1 className="cardHeader">{lgeStatus}</h1>
        <FormControl sx={{mb: 3, "--joy-fontFamily-body": "'Roboto Mono', monospace"}}>
        {lgeBonus > 0 ? (<FormLabel>Your early participant bonus:&nbsp;<b>{lgeBonus}%</b>&nbsp;</FormLabel>) : <></>}
        <FormLabel>
          {referrer === undefined ? 
            <>You don't have referral bonus, ask someone in twitter to help</>
            : <>Your referral bonus:&nbsp;<b>3%</b> (invited by {referrer.slice(0, 8)}...)</>
          }
        </FormLabel>
        <Box  display="flex" justifyContent="space-around" width="100%">
          <Input
            type="number"
              startDecorator={ethers.constants.EtherSymbol}
              value={lgeAmount}
              onChange={(e) => {
                setLgeAmount(+e.target.value || undefined);
              }}
              fullWidth
              sx={{ mr: 1, fontFamily: "'Roboto Mono', monospace" }}
              placeholder="Enter amount in ETH to participate LGE" endDecorator={
                userBalance === undefined 
                ? <></> 
                : (
                <Button variant="outlined" color="neutral" onClick={() => setLgeAmount(+ ethers.utils.formatEther(userBalance.value.sub(ethers.utils.parseUnits("1", 9))))}>
                  Max ({userBalance.formatted.substring(0, 5)} ETH)
                </Button>)
            }
          />
          <Button onClick={() => {
            if (lgeAmount === undefined) return;
            
            ReactGA.event({
              category: 'LGE',
              action: 'Buy',
              value: lgeAmount
            });
            buy?.()
          }}>Deposit ETH</Button>
        </Box>
        {
          lgeAmount && lgeTotalEth.add(ethers.utils.parseEther(`${lgeAmount}`)).gt(0) ? (
            <>
              <FormHelperText>
              Pool composition: {ethers.utils.formatEther(lgeTotalPegg)} PEGG + {ethers.utils.formatEther(lgeTotalEth.add(ethers.utils.parseEther(`${lgeAmount}`)))} ETH
            </FormHelperText>
            <FormHelperText>
              PEGG initial price: 1 PEGG = {lgeAmount / +ethers.utils.formatEther(lgeTotalPegg)} ETH
            </FormHelperText>
          </>
          ) : <></>
        }
        <FormHelperText>You will receive a share of LP tokens, which is proportional to the deposited ETH amount</FormHelperText>
        </FormControl>
        
        <List sx={{"--joy-fontFamily-body": "'Roboto Mono', monospace"}}>
            <ListItem sx={{pl: 0}}>1. Deposit ETH to LGE contract</ListItem>
            <ListItem sx={{pl: 0}}>2. In 2 months 50% of your share could be claimed</ListItem>
            <ListItem sx={{pl: 0}}>3. The remaining 50% will vest linearly over the next 2 months after the cliff</ListItem>
        </List>
        </div>
}