import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { List, ListItem, Divider, Box } from "@mui/joy";
import {ReactComponent as Logo} from './assets/Logo2.svg';
import {ReactComponent as Camelot} from './assets/Camelot2.svg';
import './App.css';
import {Lge} from "./Lge";
import {Referral} from "./Referral";


function App() {
  return (
    <div className="App">
      <div className="header">
        <Box display="flex" flexDirection="row">
          <Logo className="headerImage"/>
          <Camelot className="headerImage" />
        </Box>
        <Box>
          <h1>Pegged Finance LGE</h1>
        </Box>
        <Box><ConnectButton/></Box>
      </div>
      <div className="container">
        <Lge />
        <Referral />
      </div>
      <Divider sx={{mt: 4, mb: 4}} />
      <List>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>* The LGE will end if there are no participants for 6 hours straight</ListItem>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>* There are bonuses for early depositors:</ListItem>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>&nbsp;&nbsp;- First 50 ETH depositors will receive 10% bonus PEGG</ListItem>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>&nbsp;&nbsp;- Next 50 ETH depositors will receive 5% bonus PEGG</ListItem>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>&nbsp;&nbsp;- The rest will receive no bonus</ListItem>
            <ListItem sx={{fontFamily: "'Roboto Mono', monospace"}}>* All liquidity generated from LGE will be deposited into PEGG/WETH pool on Camelot DEX</ListItem>
        </List>
    </div>
  );
}

export default App;
