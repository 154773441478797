import React from 'react';
import ReactDOM from 'react-dom/client';
import '@rainbow-me/rainbowkit/styles.css';
import ReactGA from 'react-ga';
import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { arbitrum } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import {
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  coinbaseWallet,
  walletConnectWallet,
  rabbyWallet
} from '@rainbow-me/rainbowkit/wallets';


import { CssVarsProvider } from "@mui/joy/styles";
import App from './App';
import './index.css';

ReactGA.initialize("UA-263155302-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const { chains, provider } = configureChains([arbitrum], [publicProvider()]);

const connectors = connectorsForWallets([
  {
    groupName: 'Common used',
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ chains }),
      walletConnectWallet({ chains }),
      rabbyWallet({ chains }),
      rainbowWallet({ chains }),
      coinbaseWallet({appName: "Pegged Finance LGE", chains})
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <CssVarsProvider>
          <App />
        </CssVarsProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);