import { Share } from 'react-twitter-widgets'
import ReactGA from "react-ga";
import { useAccount } from "wagmi";
import { Button, List, ListItem, Box, Input } from "@mui/joy";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const Referral = () => {
    const { address } = useAccount();
    const referralUrl = `${window.location.origin}?referrer=${address}`;
    const text = "Participate in @pegged_finance LGE and get bonus from me 💰";

    return <div className="referral">
        <h1 className="cardHeader">Referral program</h1>
        {address ? <Box display="flex" alignItems="flex-end" justifyContent="space-evenly">
            <Input variant="outlined" color="neutral" value={referralUrl} readOnly endDecorator={
                <Button variant="soft" color="neutral" onClick={() => {
                    ReactGA.event({
                        category: 'Referral',
                        action: 'Link copied',
                      });          
                    window.navigator.clipboard.writeText(`${text} - ${referralUrl}`)}
                }><ContentCopyIcon /></Button>}/>
            <Share url={referralUrl} options={{ text, size: "large" }} />
        </Box>: <p><b>Connect wallet and get your referral link</b></p>}
        <List>
            <ListItem sx={{pl: 0, fontFamily: "'Roboto Mono', monospace"}}>1. Invite your friends to participate</ListItem>
            <ListItem sx={{pl: 0, fontFamily: "'Roboto Mono', monospace"}}>2. Using your link, friend gets&nbsp;<b>3% LGE bonus</b></ListItem>
            <ListItem sx={{pl: 0, fontFamily: "'Roboto Mono', monospace"}}>3. You get&nbsp;<b>1% LGE bonus</b></ListItem>
        </List>
    </div>
}